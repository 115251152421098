export const PrivacyPolicy = () => {
    return (
        <div id="about" className="animate__animated animate__fadeInDown">
            <div className="block rounded-lg shadow-lg bg-white w-full mb-8">

                {/* Card content */}
                <div className="flex flex-col p-8">
                    <h1 className="text-center">
                        Privacy Policy
                    </h1>

                    <h3 className="text-2xl font-Karla font-semibold my-4">Titolare del Trattamento dei Dati</h3>
                    <p>
                        Delicato Angelo<br/>
                        Indirizzo email del Titolare: angelo@thelicato.io
                    </p>

                    <h3 className="text-2xl font-Karla font-semibold my-4">Tipologie di Dati raccolti</h3>
                    <p>
                    Il Titolare non fornisce una lista di tipologie di Dati Personali raccolti.
                    </p>
                    <p>
                        Dettagli completi su ciascuna tipologia di Dati Personali raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei Dati stessi.<br/>
                        I Dati Personali possono essere liberamente forniti dall&apos;Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l&apos;uso di questa Applicazione.
                        Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l&apos;Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.<br/>
                        Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori sono incoraggiati a contattare il Titolare.<br/>
                        L&apos;eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione ha la finalità di fornire il Servizio richiesto dall&apos;Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy.<br/>
                        L&apos;Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questa Applicazione.
                    </p>

                    <h3 className="text-2xl font-Karla font-semibold my-4">Modalità e luogo del trattamento dei Dati raccolti</h3>
                    <p><b>Modalità di trattamento</b></p>
                    <p>
                        Il Titolare adotta le opportune misure di sicurezza volte ad impedire l&apos;accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali. <br/>
                        Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell&apos;organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L&apos;elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.
                    </p>
                    <p><b>Luogo</b></p>
                    <p>
                    I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.<br/>
                    I Dati Personali dell&apos;Utente potrebbero essere trasferiti in un paese diverso da quello in cui l&apos;Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l&apos;Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.
                    </p>
                    <p><b>Periodo di conservazione</b></p>
                    <p>
                        Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo richiesto dalla finalità per la quale sono stati raccolti e potrebbero essere conservati per un periodo più lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti. 
                    </p>

                    <h3 className="text-2xl font-Karla font-semibold my-4">Cookie Policy</h3>
                    <p> 
                    Questa Applicazione non fa utilizzo di Strumenti di Tracciamento.
                    </p>

                    <h3 className="text-2xl font-Karla font-semibold my-4">Ulteriori informazioni per gli utenti</h3>
                    <p><b>Base giuridica del trattamento</b></p>
                    <p>
                    Il Titolare tratta Dati Personali relativi all&apos;Utente in caso sussista una delle seguenti condizioni:
                        <ul className="list-disc ml-4">
                            <li>l&apos;Utente ha prestato il consenso per una o più finalità specifiche.</li>
                            <li>il trattamento è necessario all&apos;esecuzione di un contratto con l&apos;Utente e/o all&apos;esecuzione di misure precontrattuali;</li>
                            <li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;</li>
                            <li>il trattamento è necessario per l&apos;esecuzione di un compito di interesse pubblico o per l&apos;esercizio di pubblici poteri di cui è investito il Titolare;</li>
                            <li>il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.</li>
                        </ul>
                        È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.
                    </p>

                    <p><b>Ulteriori informazioni sul tempo di conservazione</b></p>
                    <p>
                        Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo richiesto dalla finalità per la quale sono stati raccolti e potrebbero essere conservati per un periodo più lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti.</p>
                    <p>
                    Pertanto:
                        <ul className="list-disc ml-4">
                            <li>I Dati Personali raccolti per scopi collegati all&apos;esecuzione di un contratto tra il Titolare e l&apos;Utente saranno trattenuti sino a quando sia completata l&apos;esecuzione di tale contratto.</li>
                            <li>I Dati Personali raccolti per finalità riconducibili all&apos;interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L&apos;Utente può ottenere ulteriori informazioni in merito all&apos;interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.</li>
                        </ul>
                    </p>
                    <p>
                        Quando il trattamento è basato sul consenso dell&apos;Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo per adempiere ad un obbligo di legge o per ordine di un&apos;autorità.<br/>
                        Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.
                    </p>
                    <p><b>Diritti dell&apos;Utente sulla base del Regolamento Generale sulla Protezione dei Dati (GDPR)</b></p>
                    <p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>
                    <p>
                        In particolare, nei limiti previsti dalla legge, l&apos;Utente ha il diritto di:
                        <ul className="list-disc ml-4">
                            <li>revocare il consenso in ogni momento. L&apos;Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso. </li>
                            <li>opporsi al trattamento dei propri Dati. L&apos;Utente può opporsi al trattamento dei propri Dati quando esso avviene in virtù di una base giuridica diversa dal consenso.</li>
                            <li>accedere ai propri Dati. L&apos;Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.</li>
                            <li>verificare e chiedere la rettificazione. L&apos;Utente può verificare la correttezza dei propri Dati e richiederne l&apos;aggiornamento o la correzione. </li>
                            <li>ottenere la limitazione del trattamento. L&apos;Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.</li>
                            <li>ottenere la cancellazione o rimozione dei propri Dati Personali. L&apos;Utente può richiedere la cancellazione dei propri Dati da parte del Titolare.</li>
                            <li>ricevere i propri Dati o farli trasferire ad altro titolare. L&apos;Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare.</li>
                            <li>proporre reclamo. L&apos;Utente può proporre un reclamo all&apos;autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.</li>
                        </ul>
                    </p>
                    <p>
                        Gli Utenti hanno diritto di ottenere informazioni in merito alla base giuridica per il trasferimento di Dati all&apos;estero incluso verso qualsiasi organizzazione internazionale regolata dal diritto internazionale o costituita da due o più paesi, come ad esempio l&apos;ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i loro Dati.
                    </p>
                    <p><b>Dettagli sul diritto di opposizione</b></p>
                    <p><b>
                        Quando i Dati Personali sono trattati nell&apos;interesse pubblico, nell&apos;esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.<br/>
                        Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento in qualsiasi momento, gratuitamente e senza fornire alcuna motivazione. Qualora gli Utenti si oppongano al trattamento per finalità di marketing diretto, i Dati Personali non sono più oggetto di trattamento per tali finalità. Per scoprire se il Titolare tratti Dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento. 
                    </b></p>
                    <p><b>Come esercitare i diritti</b></p>
                    <p>
                        Eventuali richieste di esercizio dei diritti dell&apos;Utente possono essere indirizzate al Titolare attraverso  i recapiti forniti in questo documento. La richiesta è gratuita e il Titolare risponderà nel più breve tempo possibile, in ogni caso entro un mese, fornendo all&apos;Utente tutte le informazioni previste dalla legge. Eventuali rettifiche, cancellazioni o limitazioni del trattamento saranno comunicate dal Titolare a ciascuno dei destinatari, se esistenti, a cui sono stati trasmessi i Dati Personali, salvo che ciò si riveli impossibile o implichi uno sforzo sproporzionato. Il Titolare comunica all&apos;Utente tali destinatari qualora egli lo richieda.
                    </p>

                    <h3 className="text-2xl font-Karla font-semibold my-4">Ulteriori informazioni sul trattamento</h3>
                    <p><b>Difesa in giudizio</b></p>
                    <p>
                        I Dati Personali dell&apos;Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell&apos;utilizzo di questa Applicazione o dei Servizi connessi da parte dell&apos;Utente.<br/>
                        L&apos;Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.
                    </p>
                    <p><b>Informative specifiche</b></p>
                    <p>Su richiesta dell&apos;Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa Applicazione potrebbe fornire all&apos;Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.</p>
                    <p><b>Log di sistema e manutenzione</b></p>
                    <p>Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l&apos;indirizzo IP Utente.</p>
                    <p><b>Informazioni non contenute in questa policy</b></p>
                    <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p>
                    <p><b>Modifiche a questa privacy policy</b></p>
                    <p>
                        <b>
                            Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su questa Applicazione nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell&apos;Utente, se necessario.
                        </b>
                    </p>

                    <h3 className="text-2xl font-Karla font-semibold my-4">Definizioni e riferimenti legali</h3>
                    <p><b>Dati Personali (o Dati)</b></p>
                    <p>
                        Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
                    <p><b>Dati di Utilizzo</b></p>
                    <p>
                        Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall&apos;Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l&apos;orario della richiesta, il metodo utilizzato nell&apos;inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all&apos;itinerario seguito all&apos;interno dell&apos;Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all&apos;ambiente informatico dell&apos;Utente.</p>
                    <p><b>Utente</b></p>
                    <p>L&apos;individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l&apos;Interessato.</p>
                    <p><b>Interessato</b></p>
                    <p>La persona fisica cui si riferiscono i Dati Personali.</p>
                    <p><b>Responsabile del Trattamento (o Responsabile)</b></p>
                    <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
                    <p><b>Titolare del Trattamento (o Titolare)</b></p>
                    <p>
                        La persona fisica o giuridica, l&apos;autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questa Applicazione.</p>
                    <p><b>Questa Applicazione</b></p>
                    <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
                    <p><b>Servizio</b></p>
                    <p>Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>
                    <p><b>Unione Europea (o UE)</b></p>
                    <p>Salvo ove diversamente specificato, ogni riferimento all&apos;Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell&apos;Unione Europea e dello Spazio Economico Europeo.</p>
                    <p><b>Riferimenti legali</b></p>
                    <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.</p>

                </div>

            </div>
        </div>
    );
}